<template>
  <div class="con-wrap">
    <CarrotTitle :title="hq.name"></CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <table class="table-row table-serach">
            <tbody>
            <tr>
              <th width="180">기간</th>
              <td>
                <CarrotDatePicker v-model="hq.sdate" class="w-110px float-left"></CarrotDatePicker>
                <span class="float-left ml-10 mr-10">~</span>
                <CarrotDatePicker v-model="hq.edate" class="w-110px float-left"></CarrotDatePicker>
              </td>
            </tr>
            <tr>
              <th width="180">검색</th>
              <td>
                <select class="w-200px" v-model="hq.type">
                  <option value="src">발신번호</option>
                  <option value="dst">수신번호</option>
                </select>
                <input type="text" class="w-200px" v-model="hq.search">
              </td>
            </tr>
            </tbody>
          </table>
          <button class="btn-default float-right mt-10" @click="hq.doSearch">검색</button>
          <div class="clear"></div>
          <div class="mt-40 mb-20">
            <button class="btn-default float-right" @click="hq.downExcel">Excel 다운로드</button>
            <div class="clear"></div>
          </div>
          <table class="table-col">
            <colgroup>
            </colgroup>
            <thead>
            <tr>
              <th width="70">No</th>
              <th width="150">발신번호</th>
              <th width="100">수신번호</th>
              <th width="200">통화시작시간</th>
              <th width="120">통화시간</th>
              <th width="100">통화내용듣기</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(irow, i) in hq.list" :key="i">
              <td>{{ irow.num }}</td>
              <td>{{ irow.src }}</td>
              <td class="txt-ellipsis">{{ irow.dst }}</td>
              <td class="txt-ellipsis">{{ irow.calldate }}</td>
              <td class="txt-ellipsis">{{irow.duration}}</td>
              <td class="txt-center ">
                <!--
                <audio controls v-if="irow.mp3">
                  <source :src="'http://'+irow.mp3" type="audio/mp3"></audio>
                   -->
                <a class="pointer txt-blue" @click="hq.downMp3('http://'+irow.mp3)">내용듣기</a>
              </td>

            </tr>
            <tr v-if="hq.total==0">
              <td colspan="6">정보가 없습니다.</td>
            </tr>
            </tbody>
          </table>
          <CarrotPaging :total="hq.total" :list_per_page="hq.rows" v-model="hq.page" @change="hq.doSearch"></CarrotPaging>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import {useRoute, useRouter} from 'vue-router'
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'

import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import { useStore } from 'vuex';
export default {
  layout:"myPIMS",

  components: {
    CarrotDatePicker
  },
  setup() {
    const router = new useRouter();
    const route  = new useRoute();
    const store  = useStore();

    const hq = reactive({
      idx:0,
      name:"",
      isAuth : false,
      page   : 1,
      rows   : 20,
      sdate  :"",
      edate  : "",
      phone  : "",
      search : "",
      type  : "src",
      join_sort:"",
      orderby:"",

      analysis : [],
      list : [], total : 0,
      doSearchOffice : () => {
        hq.doSearch();
      },
      doSearchTeam : () => {
        hq.doSearch();
      },
      doSearchJoinday : () => {

        if(hq.orderby=='joindayasc')
          hq.orderby='joindaydesc'
        else
          hq.orderby='joindayasc'
        hq.doSearch();
      },
      doSearchOrderBy : (orderby) => {
        hq.orderby=orderby;
        hq.doSearch();
      },
      downExcel : () => {
        // 로컬 테스트 시 local.intranet.mxm.kr, https://api.carrotians.net
        let url = "https://api.carrotians.net" + "/excel/eicn_view"
            + "?idx="  + hq.idx
            + "&sdate="  + hq.sdate
            + "&edate="  + hq.edate
            + "&search=" + hq.search
            + "&type="  + hq.type;
        window.open(url, '_blank').focus();
      },
      downMp3 : (url) => {
        // 로컬 테스트 시 local.intranet.mxm.kr, https://api.carrotians.net
        window.open(url, '_blank').focus();
      },
      viewHQ : (idx) => {
        router.push({
          name   : 'myPIMS-PersonalInfoList_tab1-idx',
          params : { idx:idx }
        });
      },


      doSearch : () => {
        let params = {
          idx: hq.idx,
          page   : hq.page,
          rows   : hq.rows,
          sdate  : hq.sdate,
          edate  : hq.edate,
          search : hq.search,
          type  : hq.type
        };

        axios.get('/rest/mypims/eicnDetail', { params : params }).then((res) => {
          if( res.data.err == 0 ){
            hq.list   = res.data.list;
            hq.total  = res.data.total;
            hq.name= res.data.hq_ename+"("+res.data.hq_kname+") 통화 목록(Call List)";
          } else {
            console.info(res.data.err_msg);
          }
        });
      }
    });

    onMounted(() => {
      // Mounted
      // console.log(store.state.pd_auth, store.state.position );
      // 현재는 팀 구성이 되어있지 않으므로 스탭을 지정하여 체크하도록 변경, 3995:슈리, 19054:헤이즐

      hq.idx = route.params.idx;

      let td = new Date();
      let nd = new Date(td.getFullYear(), td.getMonth(), td.getDate());
      hq.sdate= [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
      hq.edate= [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');

      if ( !hq.idx ) {
        router.back(-1);
      }


      if( store.state.isCmsAdmin == false  ) {
        //if( store.state.pd_auth != true && store.state.position != '리드' ) {
        router.go(-1);
        Swal.fire({
          title : '통화관리시스템',
          text  : '접근 권한이 없습니다.'
        });
        return;
      }

      if( store.state.pd_auth == true ) {
        hq.isAuth = true;
      }

      hq.doSearch();
    });

    onUnmounted(() => {
      // UnMounted
    });

    return {hq};
  }
}
</script>

<style lang="scss" scoped>
</style>